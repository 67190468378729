import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';

export type CardTitleProps = HTMLAttributes<HTMLHeadingElement>;

export const CardTitle: FC<CardTitleProps> = ({ className, children, ...props }) => (
  <h3
    className={clsx(
      className,
      'card__title text-xs sm:text-sm font-xs text-gray-900 whitespace-no-wrap overflow-hidden',
    )}
    {...props}
  >
    <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      {children}
    </span>
  </h3>
);
